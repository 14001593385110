import React, { useState } from "react";
import { useParams } from "react-router-dom";
import EpworthV3 from "../components/depistage/EpworthV3";
import "../styles/Depistage.scss";
import { Button } from "primereact/button";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import Berlin from "../components/depistage/Berlin";
import { getBack } from "../services/Utils";

export default function Depistage() {
  const { test } = useParams();
  const [isOpened, setIsOpened] = useState(false);

  const GetTest = (t) => {
    let content;
    switch (t) {
      case "epworth":
        content = <EpworthV3 setOpened={() => setIsOpened(true)} />;
        break;
      case "berlin":
        content = <Berlin setOpened={() => setIsOpened(true)} />;
        break;
      default:
        content = <></>;
        break;
    }

    return content;
  };

  const confirm1 = () => {
    confirmDialog({
      message: "Êtes-vous sûr de vouloir quitter le questionnaire ?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      defaultFocus: "accept",
      accept: getBack,
      acceptLabel: "Oui",
      rejectLabel: "Non",
    });
  };

  return (
    <div id="homePage" style={{ justifyContent: "flex-start" }}>
      <div
        style={{
          textAlign: "left",
          marginLeft: "10px",
          marginTop: "10px",
          fontSize: "12px",
        }}
      >
        <Button
          severity="info"
          //   label="Retour Questionnaires"
          icon="pi pi-times"
          onClick={isOpened ? confirm1 : getBack}
          style={{
            fontSize: "12px",
          }}
          outlined
        />
        <ConfirmDialog className="depist-dial" />
      </div>
      {GetTest(test)}
    </div>
  );
}
