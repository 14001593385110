import React, { useEffect } from "react";
import "../styles/Actions_style.scss";
import Loupe from "../ressources/loupe.png";
import Patient from "../ressources/patient.png";
import Jeune from "../ressources/jeune.png";
import { createPopup } from "@typeform/embed";

import "@typeform/embed/build/css/popup.css";
import GA4Events from "../events/GA4Events";

function QuizzJeunesse() {
  // const effects = ["left", "right", "bottom"];
  const { toggle: toggleLess14 } = createPopup("ypqJhN8P");
  const { toggle: toggleMore14 } = createPopup("pt24CDE1");

  const handleLess14Click = () => {
    GA4Events.actionHome("-14");
    toggleLess14(); // Affiche la popup
  };

  const handleMore14Click = () => {
    GA4Events.actionHome("+14");
    toggleMore14(); // Affiche la popup
  };

  return (
    <div id="action-sect">
      <div
        className="action-blocs slide-in-left zoom-in-hard"
        id="slide-block-1"
        onClick={handleLess14Click}
      >
        <img
          src={Patient}
          className="opacity-delay-effect-1"
          alt="Partenaire logo"
        />
        <h2 className="opacity-delay-effect-2">J'ai -14 ans</h2>
        <p className="opacity-delay-effect-3">Je participe au questionnaire</p>
      </div>
      <div
        className="action-blocs slide-in-right zoom-in-hard"
        id="slide-block-2"
        onClick={handleMore14Click}
      >
        <img
          src={Loupe}
          className="opacity-delay-effect-1"
          alt="Medecin logo"
        />
        <h2 className="opacity-delay-effect-2">J'ai +14 ans</h2>
        <p className="opacity-delay-effect-3">Je participe au questionnaire</p>
      </div>
    </div>
  );
}

export default QuizzJeunesse;
