import React, { useRef, useState } from "react";
import { Stepper } from "primereact/stepper";
import { StepperPanel } from "primereact/stepperpanel";
import { Button } from "primereact/button";
import { SelectButton } from "primereact/selectbutton";
import { getBack } from "../../services/Utils";

const questions = [
  "Pendant que vous êtes occupé à lire un document ?",
  "Devant la télévision ou au cinéma ?",
  "Assis inactif dans un lieu public (salle d'attente, théâtre,...)",
  "Passager, depuis au moins une heure sans interruptions, d'une voiture ou d'un transport en commun (train, bus, avion, ...)",
  "Allongé pour une sieste, lorsque les circonstances le permettent",
  "En position assise au cours d'une conversation (ou au téléphone) avec un proche",
  "Tranquillement assis à table à la fin d'un repas sans alcool",
  "Au volant d'une voiture immobilisée depuis quelques minutes dans un embouteillage",
  "Résultats",
];

export default function Epworth3({ setOpened }) {
  const stepperRef = useRef(null);
  const [value, setValue] = useState(Array(questions.length).fill(null));
  const [scores, setScores] = useState(Array(questions.length).fill(null));
  const [totalScore, setTotalScore] = useState(0);
  const [result, setResult] = useState("");
  const [startTest, setStartTest] = useState(false);

  const handleScoreChange = (score, index) => {
    const newScores = [...scores];
    newScores[index] = parseInt(score);
    setScores(newScores);
    setTotalScore(newScores.reduce((acc, curr) => acc + curr, 0));

    if (index === questions.length - 2) {
      getTxtResultat(newScores);
    }

    setOpened(true);
  };

  function getTxtResultat(score) {
    if (score <= 7) {
      setResult("Votre sommeil est considéré comme normal et suffisant");
    } else if ((score >= 8) & (score <= 10)) {
      setResult(
        "Votre sommeil est considéré comme de qualité moyenne et améliorable"
      );
    } else if ((score >= 11) & (score <= 14)) {
      setResult(
        "Votre somnolence est considérée comme anormale et excessive, potentiellement pathologique. <br/> Une exploration du sommeil est recommandée. Parlez-en à votre médecin."
      );
    } else {
      setResult(
        "Votre somnolence est considérée comme sévère. \n\n Consultez un médecin rapidement afin de procéder a une recherche de la cause."
      );
    }
  }

  function SelectValue(index) {
    return (
      <SelectButton
        value={value[index]}
        onChange={(e) => {
          setValue(value.map((v, i) => (i === index ? e.value : v)));
          handleScoreChange(e.value, index);
        }}
        options={[0, 1, 2, 3]}
        key={`k${index}`}
        className="select-b-round"
      />
    );
  }

  const StartQuest = () => {
    setStartTest(true);
  };

  return (
    <section>
      {!startTest ? (
        <>
          <div
            style={{
              backgroundColor: "#9BCAC0",
              color: "white",
              borderRadius: "10px",
              padding: "2px 10px 5px 10px",
              margin: "15px 5vw",
            }}
          >
            <h1>Test d'Epworth</h1>
          </div>
          <div style={{ margin: "30px 7vw", textJustify: "justify" }}>
            <p style={{ textAlign: "justify", textJustify: "auto" }}>
              Afin de pouvoir <b>évaluer</b> chez vous une éventuelle{" "}
              <b>somnolence dans la journée</b>, vous pouvez répondre aux
              questions de l’échelle de somnolence d’Epworth. <br />
              <br />
              Voici quelques <b>situations relativement courantes</b> où nous
              vous demandons d’<b>évaluer le risque de vous assoupir</b>.
            </p>
            <br />
            <p
              style={{
                textAlign: "justify",
                textJustify: "auto",
                textDecoration: "underline",
              }}
            >
              Si vous n’avez pas été récemment dans l’une de ces situations,
              essayez d’imaginer comment cette situation pourrait vous affecter.
            </p>
            <br />
            Durée estimée : 30 secondes
            <br />
            <br />
            <Button
              label="Démarrer le test"
              icon="pi pi-play"
              onClick={StartQuest}
              style={{ marginRight: "10px" }}
            />
          </div>
        </>
      ) : (
        <div
          className="card flex justify-content-center"
          style={{ margin: "1vh 10vw" }}
        >
          <Stepper
            ref={stepperRef}
            style={{ flexBasis: "50rem", backgroundColor: "none" }}
            linear
          >
            {questions.map((question, index) => {
              if (index < questions.length - 1) {
                return (
                  <StepperPanel header="question" key={index}>
                    <div
                      style={{
                        backgroundColor: "#9BCAC0",
                        color: "white",
                        borderRadius: "10px",
                        padding: "2px 10px 5px 10px",
                      }}
                    >
                      <div
                        style={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          fontSize: "20px",
                          fontWeight: "bold",
                        }}
                      >
                        Évaluation du risque de vous assoupir
                      </div>
                    </div>
                    <div className="flex flex-column h-12rem ">
                      <br />
                      <br />
                      <div
                        className="border-2 border-dashed surface-border border-round surface-ground flex-auto flex justify-content-center align-items-center font-medium"
                        style={{
                          marginBottom: "30px",
                          fontSize: "20px",
                          fontWeight: "bold",
                        }}
                      >
                        {question}
                      </div>
                      <br />
                      <div>{SelectValue(index)}</div>
                    </div>
                    <div
                      style={{
                        textAlign: "left",
                        fontSize: "18px",
                        marginTop: "40px",
                        color: "#6f6f6f",
                      }}
                    >
                      0 = aucun risque de somnoler <br />
                      1 = faible risque <br />
                      2 = risque moyen <br />
                      3 = fort risque <br />
                      <br />
                    </div>
                    <div
                      className="flex pt-4 justify-content-start"
                      style={{ marginTop: "30px" }}
                    >
                      {/* {index !== 0 ? (
                        <Button
                          label="Précédent"
                          severity="secondary"
                          icon="pi pi-arrow-left"
                          onClick={() => stepperRef.current.prevCallback()}
                          style={{ marginRight: "10px" }}
                          outlined
                        />
                      ) : (
                        <></>
                      )} */}

                      <Button
                        label="Suivant"
                        icon="pi pi-arrow-right"
                        onClick={() => stepperRef.current.nextCallback()}
                        style={
                          scores[index] !== null
                            ? { visibility: "visible" }
                            : { visibility: "hidden" }
                        }
                      />
                      {index !== 0 ? (
                        <div
                          style={{
                            marginRight: "10px",
                            textDecoration: "underline",
                            marginTop: "20px",
                          }}
                          onClick={() => stepperRef.current.prevCallback()}
                        >
                          Précédent
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </StepperPanel>
                );
              } else {
                return (
                  <StepperPanel header="Résultats">
                    <div
                      className="flex flex-column h-12rem"
                      style={{
                        backgroundColor: "white",
                        padding: "5px 15px",
                        borderRadius: "10px",
                        fontSize: "1.5rem",
                      }}
                    >
                      <p className="result" style={{ fontSize: "1.5rem" }}>
                        {totalScore ? (
                          <>
                            <b style={{ fontSize: "1.2rem" }}>Score : </b>
                            <br /> <br />
                            {totalScore}
                          </>
                        ) : (
                          <></>
                        )}
                      </p>
                      <hr style={{ borderColor: "#fbfbfb", width: "50%" }} />
                      <br />
                      <p
                        className="result"
                        style={{ fontSize: "1.5rem", padding: "auto 5px" }}
                      >
                        <>
                          <b>Résultats : </b> <br /> <br />
                          <span style={{ fontSize: "1.2rem" }}>{result}</span>
                        </>
                      </p>
                    </div>
                    <br />
                    <br />
                    <div className="flex pt-4 justify-content-start">
                      <Button
                        label="Retour"
                        severity="secondary"
                        icon="pi pi-arrow-left"
                        onClick={() => stepperRef.current.prevCallback()}
                      />
                      <Button
                        label="Fermer"
                        severity="secondary"
                        icon="pi pi-times"
                        onClick={getBack}
                        style={{ marginLeft: "10px" }}
                      />
                    </div>
                  </StepperPanel>
                );
              }
            })}
          </Stepper>
        </div>
      )}
    </section>
  );
}
