import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Team from "./pages/Team";
import Quizz from "./pages/Quizz";
import { fairyDustCursor } from "cursor-effects";

import "./styles/App.css";
import "./styles/Mobile.scss";
import "./styles/Effects.scss";

import { ScrollToTop } from "./services/ScrollToTop";
import { Template } from "./pages/Template";
import ActionsDepistage from "./components/Actions_Depistages";
import Depistage from "./pages/Depistage";

function App() {
  new fairyDustCursor({
    // colors: ["#e7b5a8", "#99c8be", "#f03e6a", "#f03e6a", "#FFF888"],
    colors: ["#e7b5a8", "#99c8be"],
  });

  return (
    <div className="App">
      <ScrollToTop />
      <Routes>
        <Route exact path="/quizz/:quizzType" element={<Quizz />} />
        <Route
          exact
          path="/prevention/depistages"
          element={<Template content={<ActionsDepistage />} />}
        />
        <Route
          exact
          path="/prevention/depistages/:test"
          element={<Depistage />}
        />
        <Route exact path="/equipe" element={<Team />} />
        <Route exact path="/" element={<Home />} />
      </Routes>
    </div>
  );
}

export default App;
