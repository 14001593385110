import { Button } from "primereact/button";
import { SelectButton } from "primereact/selectbutton";
import { Stepper } from "primereact/stepper";
import { StepperPanel } from "primereact/stepperpanel";
import React, { useRef, useState } from "react";

const questions = [
  "Pendant que vous êtes occupé à lire un document",
  "Devant la télévision ou au cinéma",
  "Assis inactif dans un lieu public (salle d'attente, théâtre,...)",
  "Passager, depuis au moins une heure sans interruptions, d'une voiture ou d'un transport en commun (train, bus, avion, ...)",
  "Allongé pour une sieste, lorsque les circonstances le permettent",
  "En position assise au cours d'une conversation (ou au téléphone) avec un proche",
  "Tranquillement assis à table à la fin d'un repas sans alcool",
  "Au volant d'une voiture immobilisée depuis quelques minutes dans un embouteillage",
  "Résultats",
];

export default function Berlin({ setOpened }) {
  const stepperRef = useRef(null);
  const [value, setValue] = useState(Array(questions.length).fill(null));
  const [scores, setScores] = useState(Array(questions.length).fill(null));
  const [totalScore, setTotalScore] = useState(0);
  const [result, setResult] = useState("");
  const [startTest, setStartTest] = useState(false);

  const handleScoreChange = (score, index) => {
    const newScores = [...scores];
    newScores[index] = parseInt(score);
    setScores(newScores);
    setTotalScore(newScores.reduce((acc, curr) => acc + curr, 0));

    if (index === questions.length - 2) {
      getTxtResultat(newScores);
    }
  };

  function getTxtResultat(score) {
    if (score < 10) {
      setResult("Votre score est normal");
    } else {
      setResult("Votre score est élevé, vous devriez consulter un médecin");
    }
  }

  function SelectValue(index) {
    return (
      <SelectButton
        value={value[index]}
        onChange={(e) => {
          setValue(value.map((v, i) => (i === index ? e.value : v)));
          handleScoreChange(e.value, index);
        }}
        options={[0, 1, 2, 3]}
        key={`k${index}`}
      />
    );
  }

  function StartQuest() {
    setStartTest(true);
    setOpened(true);
  }

  return (
    <section>
      <h1>Test de Berlin</h1>
      <br />

      {!startTest ? (
        <div style={{ margin: "auto 7vw", textJustify: "justify" }}>
          <p style={{ textAlign: "justify", textJustify: "auto" }}>
            Afin de pouvoir évaluer chez vous une éventuelle somnolence dans la
            journée, vous pouvez répondre aux questions de l’échelle de
            somnolence d’Epworth. <br />
            Voici quelques situations relativement courantes où nous vous
            demandons d’évaluer le risque de vous assoupir.
          </p>
          <br />
          <p style={{ textAlign: "justify", textJustify: "auto" }}>
            Si vous n’avez pas été récemment dans l’une de ces situations,
            essayez d’imaginer comment cette situation pourrait vous affecter.
          </p>
          <br />
          Durée estimée : 30 secondes
          <br />
          <br />
          <Button
            label="Démarrer le test"
            icon="pi pi-play"
            onClick={() => StartQuest()}
            style={{ marginRight: "10px" }}
          />
        </div>
      ) : (
        <div
          className="card flex justify-content-center"
          style={{ margin: "5vh 10vw" }}
        >
          <Stepper ref={stepperRef} style={{ flexBasis: "50rem" }} linear>
            {questions.map((question, index) => {
              if (index < questions.length - 1) {
                return (
                  <StepperPanel header="question" key={index}>
                    <div
                      className="flex flex-column h-12rem "
                      style={{ marginBottom: "20px" }}
                    >
                      <br />
                      <div
                        className="border-2 border-dashed surface-border border-round surface-ground flex-auto flex justify-content-center align-items-center font-medium"
                        style={{ marginBottom: "10px", fontSize: "20px" }}
                      >
                        {question}
                      </div>
                      <br />
                      <div>{SelectValue(index)}</div>
                    </div>
                    <div
                      style={{
                        textAlign: "left",
                        fontSize: "15px",
                      }}
                    >
                      0 = aucun risque de somnoler <br />
                      1 = faible risque <br />
                      2 = risque moyen <br />
                      3 = fort risque <br />
                      <br />
                    </div>
                    <div className="flex pt-4 justify-content-start">
                      {index !== 0 ? (
                        <Button
                          label="Retour"
                          severity="secondary"
                          icon="pi pi-arrow-left"
                          onClick={() => stepperRef.current.prevCallback()}
                          style={{ marginRight: "10px" }}
                          outlined
                        />
                      ) : (
                        <></>
                      )}

                      <Button
                        label="Suivant"
                        icon="pi pi-arrow-right"
                        onClick={() => stepperRef.current.nextCallback()}
                        style={
                          scores[index] !== null
                            ? { visibility: "visible" }
                            : { visibility: "hidden" }
                        }
                      />
                    </div>
                  </StepperPanel>
                );
              } else {
                return (
                  <StepperPanel header="Résultats">
                    <div className="flex flex-column h-12rem">
                      <p className="result">
                        {totalScore ? `Score : ${totalScore}` : <></>}
                      </p>
                      <p className="result">
                        {result ? `Résultat : ${result}` : <></>}
                      </p>
                    </div>
                    <div className="flex pt-4 justify-content-start">
                      <Button
                        label="Back"
                        severity="secondary"
                        icon="pi pi-arrow-left"
                        onClick={() => stepperRef.current.prevCallback()}
                      />
                    </div>
                  </StepperPanel>
                );
              }
            })}
          </Stepper>
        </div>
      )}
    </section>
  );
}
