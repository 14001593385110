import React, { useEffect } from "react";
import "../styles/Diapo_style.scss";
import LisaLogo from "../ressources/LisaConnect-couleur.png";
import LisaLogoLight from "../ressources/LisaConnect-couleur_350px.png";
import { Link, useNavigate } from "react-router-dom";

function Diapo() {
  const navigate = useNavigate();

  return (
    <div id="diapo-sect">
      {/* <Link to="/" className="zoom-in-light"> */}
      <img
        src={LisaLogoLight}
        id="lisa-birds"
        className="opacity-effect zoom-in-light"
        alt="LisaConnect logo"
        onClick={() => navigate("/")}
      />
      {/* </div> */}
      {/* </Link> */}
    </div>
  );
}

export default Diapo;
