import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { PrimeReactProvider } from "primereact/api";

import "./styles/index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import ReactGA from "react-ga4";

import "primereact/resources/themes/lara-light-teal/theme.css";
import "primeicons/primeicons.css"; //icons

ReactGA.initialize("G-PC8J5DZY6T");

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <PrimeReactProvider>
        <App />
      </PrimeReactProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
