import ReactGA from "react-ga4";

const GA4Events = {
  // printSudoku: () => {
  //     ReactGA.event({
  //         category: 'Sudoku',
  //         action: 'Print',
  //         label: 'Print Sudoku'
  //     });
  // },
  //   printSudoku: () => {
  //     ReactGA.event("Prints", {
  //       method: "Sudoku",
  //     });
  //   },
  //   printHistoires: () => {
  //     ReactGA.event({
  //       category: "Histoires",
  //       action: "Print",
  //       label: "Print Histoires",
  //     });
  //   },
  //   prints: (ptype) => {
  //     ReactGA.event("Prints", {
  //       method: ptype,
  //     });
  //   },
  //   actionHome: (atype) => {
  //     ReactGA.event("custom_buttons", {
  //       method: atype,
  //     });
  //   },

  // ------------------------------------------------------------

  //   actionPatient: () => {
  //     ReactGA.event("custom_buttons", {
  //       method: "Patient",
  //     });
  //   },
  //   actionMedecin: () => {
  //     ReactGA.event("custom_buttons", {
  //       method: "Medecin",
  //     });
  //   },
  //   actionPartenaire: () => {
  //     ReactGA.event("custom_buttons", {
  //       method: "Partenaire",
  //     });
  //   },

  actionHome: (atype) => {
    ReactGA.event({
      category: "Custom Buttons",
      action: atype,
      label: `Clicked ${atype}`,
    });
  },

  actionPatient: () => {
    ReactGA.event({
      category: "Custom Buttons",
      action: "Patient",
      label: "Clicked Patient",
    });
  },
  actionMedecin: () => {
    ReactGA.event({
      category: "Custom Buttons",
      action: "Medecin",
      label: "Clicked Medecin",
    });
  },
  actionPartenaire: () => {
    ReactGA.event({
      category: "Custom Buttons",
      action: "Partenaire",
      label: "Clicked Partenaire",
    });
  },
  actionDepistageQuizz: (atype) => {
    ReactGA.event({
      category: "Custom Buttons",
      action: "Dépistage",
      label: atype,
    });
  },
};

export default GA4Events;
