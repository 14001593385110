export function alpha() {
  const data = "bGlzYQ==";
  return atob(data);
}

export function beta() {
  const segment = "bmVjdC5mcg==";
  return atob(segment);
}

export function gamma() {
  const piece = "Y29u";
  return atob(piece);
}

export function delta() {
  const fragment = "dGFjdA==";
  return atob(fragment);
}

export function epsilon() {
  const symbol = "QA==";
  return atob(symbol);
}

export function retrieveInfo() {
  return gamma() + delta() + epsilon() + alpha() + gamma() + beta();
}
export function omega() {
  const data = "KzMzMQ==";
  return atob(data);
}

export function psi() {
  const segment = "ODU0";
  return atob(segment);
}

export function chi() {
  return "3931343539";
}

export function retrievePhone() {
  let result = "";
  for (let i = 0; i < chi().length; i += 2) {
    result += String.fromCharCode(parseInt(chi().substr(i, 2), 16));
  }
  return omega() + psi() + result;
}

export const getBack = () => {
  window.history.back();
};
