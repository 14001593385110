import React, { useEffect } from "react";
import Diapo from "../components/Diapo";
import Actions from "../components/Actions";
import Footer from "../components/Footer";
import Us from "../components/Us";
import PopupDespistage from "../components/depistage/PopupDespistage";

function Home() {
  return (
    <div id="homePage">
      <Diapo />
      <Actions />
      <Us />
      {/* <PopupDespistage /> */}
      <Footer />
    </div>
  );
}

export default Home;
