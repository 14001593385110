import React from "react";
import "../styles/Footer_style.scss";
import { retrieveInfo, retrievePhone } from "../services/Utils";
import MapLink from "./MapLink";

function Footer() {
  function showEmail(e) {
    if (!document.getElementById("email").innerHTML.includes("@")) {
      e.preventDefault();
      const alto = "6d61696c";
      const x = retrieveInfo();
      const pi = "746f3a";

      let result = "";
      let hty = alto + pi;
      for (let i = 0; i < hty.length; i += 2) {
        result += String.fromCharCode(parseInt(hty.substr(i, 2), 16));
      }
      document.getElementById("email").href = result + x;
      document.getElementById("email").innerHTML = x;
    }
  }
  function showSmartp(e) {
    if (!document.getElementById("smartp").href.includes("tel:")) {
      e.preventDefault();
      const prefix = "74656c3a";
      let result = "";
      for (let i = 0; i < prefix.length; i += 2) {
        result += String.fromCharCode(parseInt(prefix.slice(i, i + 2), 16));
      }

      document.getElementById("smartp").href = result + retrievePhone();
      document.getElementById("smartp").innerHTML = retrievePhone();
    }
  }
  return (
    <footer id="footer-blc">
      <div>
        <span>
          <h3>Lisa Connect</h3>
          <MapLink />
          <br />
        </span>
      </div>
      <div className="">
        <h3> Nous contacter</h3>
        <div>
          <a
            className={"nav-button"}
            id="email"
            onClick={(e) => {
              showEmail(e);
            }}
          >
            Email
          </a>
        </div>
        <div>
          <a
            className={"nav-button"}
            id="smartp"
            onClick={(e) => {
              showSmartp(e);
            }}
          >
            Téléphone
          </a>
        </div>
        <br />
      </div>

      <div>© LisaConnect 2024, All Rights Reserved</div>
    </footer>
  );
}

export default Footer;
