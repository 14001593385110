import React from "react";
import "../styles/Actions_style.scss";
import Loupe from "../ressources/loupe.png";
import Doigt from "../ressources/doigt.png";

import "@typeform/embed/build/css/popup.css";
import GA4Events from "../events/GA4Events";
import { useNavigate } from "react-router-dom";

function ActionsDepistage() {
  const navigate = useNavigate();

  const tests = [
    {
      title: "Dépistage somnolence",
      name: "Test d'Epworth",
      infos: "Durée : 30 sec",
      img: Doigt,
      alt: "Loupe",
      effects: "zoom-in-hard slide-in-left",
      bgColor: "rgb(0 150 255 / 23%)",
      action: () => {
        GA4Events.actionDepistageQuizz("Test Epworth");
        navigate("/prevention/depistages/epworth");
      },
      // link: "/prevention/depistages/covid-19",
      link: null,
    },
    {
      title: "Dépistage apnée du sommeil",
      name: "Test de Berlin",
      infos: "Durée : 1 minute",
      img: Doigt,
      alt: "Loupe",
      effects: "zoom-in-hard slide-in-left",
      bgColor: "rgb(231 230 168)",
      // action: () => {
      //   GA4Events.actionDepistageQuizz("Test Berlin");
      //   navigate("/prevention/depistages/berlin");
      // },
      action: null,
      // link: "/prevention/depistages/covid-19",
      link: null,
    },
    {
      title: "A venir ...",
      name: "Test vue, etc...",
      infos: null,
      img: Loupe,
      alt: "Loupe",
      effects: "zoom-in-hard slide-in-right",
      bgColor: "rgb(168 231 181)",
      action: null,
      link: null,
    },
  ];

  function GetBlocks(data) {
    if (data.link) {
      return (
        <a
          className={`action-blocs ${data?.effects}`}
          href={data?.link}
          target="_blank"
          rel="noreferrer"
          id="slide-block-1"
          onClick={data?.action}
          style={data.bgColor ? { backgroundColor: data?.bgColor } : {}}
        >
          <img
            className="opacity-delay-effect-1"
            style={{ marginTop: "10px" }}
            src={data?.img}
            alt={data?.alt}
          />
          <h2 className="opacity-delay-effect-2">{data?.title}</h2>
          <p className="opacity-delay-effect-3">{data?.name}</p>
          {data.infos ? (
            <p style={{ fontSize: "12px" }}>{data.infos}</p>
          ) : (
            <></>
          )}
        </a>
      );
    } else {
      return (
        <div
          className={`action-blocs ${data?.effects}`}
          id="slide-block-4"
          onClick={data?.action}
          style={data.bgColor ? { backgroundColor: data?.bgColor } : {}}
        >
          <img
            className="opacity-delay-effect-1"
            style={{ marginTop: "10px" }}
            src={data?.img}
            alt={data?.alt}
          />
          <h2 className="opacity-delay-effect-2">{data?.title}</h2>
          <p className="opacity-delay-effect-3">{data?.name}</p>
          {data.infos ? (
            <p className="opacity-delay-effect-4" style={{ fontSize: "12px" }}>
              {data.infos}
            </p>
          ) : (
            <></>
          )}
        </div>
      );
    }
  }

  return (
    <div id="action-sect">
      {tests.map((test, index) => (
        <GetBlocks key={index} {...test} />
      ))}
    </div>
  );
}

export default ActionsDepistage;
