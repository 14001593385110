import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Diapo from "../components/Diapo";
import Footer from "../components/Footer";
import Us from "../components/Us";
import QuizzJeunesse from "../components/Quizz_Jeunesse";
import QuizzAdulte from "../components/Quizz_Jeunesse";
import QuizzSenior from "../components/Quizz_Jeunesse";
import DiapoEMeuse from "../components/Diapo_Emeuse";

const QUIZZ_COMPONENTS = {
  jeunesse: QuizzJeunesse,
  // adulte: QuizzAdulte,
  // senior: QuizzSenior,
};

function Quizz() {
  const { quizzType } = useParams();

  const QuizzComponent =
    QUIZZ_COMPONENTS[quizzType] ||
    (() => (
      <div
        style={{
          flexGrow: 1, // Cela permet de prendre tout l'espace disponible
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="slide-in-bottom">Choix de quizz non reconnu</div>
      </div>
    ));

  return (
    <div
      id="homePage"
      style={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <DiapoEMeuse />
      <div style={{ flex: 1 }}>
        <QuizzComponent />
      </div>
      <Footer />
    </div>
  );
}

export default Quizz;
