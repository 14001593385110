import React from "react";
import Diapo from "../components/Diapo";
import Footer from "../components/Footer";

import { fairyDustCursor } from "cursor-effects";

export const Template = ({ content }) => {
  new fairyDustCursor({
    colors: ["#e7b5a8", "#99c8be"],
  });

  return (
    <div id="homePage">
      <Diapo />
      {content}
      <Footer />
    </div>
  );
};
