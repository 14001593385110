import React, { useEffect, useRef } from "react";
import "../styles/Us.scss";
import { useNavigate } from "react-router-dom";

export default function Us() {
  const usRef = useRef(null);
  const [isVisible, setIsVisible] = React.useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        // console.log(entries);
        if (entries[0].isIntersecting) {
          setIsVisible(true);
          observer.disconnect(); // Une fois que l'animation est déclenchée, nous n'avons plus besoin de l'observer
        }
      },
      {
        threshold: 0.2, // Au moins 20% du composant doit être visible
      }
    );

    if (usRef.current) {
      observer.observe(usRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);
  return (
    <div>
      <div id="us-blc" ref={usRef}>
        <div
          className={isVisible ? "rotation visible" : "rotation"}
          onClick={() => navigate("/equipe")}
        >
          <div className={"zoom-in-hard"}>
            <span>Qui sommes-nous ?</span>
          </div>
        </div>
      </div>
    </div>
  );
}
