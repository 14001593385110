import React, { useEffect } from "react";
import "../styles/Actions_style.scss";
import Loupe from "../ressources/loupe.png";
import Doigt from "../ressources/doigt.png";
import Patient from "../ressources/patient.png";
import Jeune from "../ressources/jeune.png";
import { AppCultureSante } from "../config";
import { createPopup } from "@typeform/embed";

import "@typeform/embed/build/css/popup.css";
import GA4Events from "../events/GA4Events";
import { useNavigate } from "react-router-dom";

function Actions() {
  const navigate = useNavigate();

  const { toggle: toggleMedecin } = createPopup("AV1TnSzV");
  const { toggle: togglePartenaire } = createPopup("bR0cvjSG");

  const handleMedecinClick = () => {
    GA4Events.actionHome("Medecin");
    toggleMedecin(); // Affiche la popup
  };

  const handlePartenaireClick = () => {
    GA4Events.actionHome("Partenaire");
    togglePartenaire(); // Affiche la popup
  };

  const handleDepistageClick = () => {
    GA4Events.actionHome("Depistages");
    navigate("/prevention/depistages");
  };

  return (
    <div id="action-sect">
      {/* <div
        className="action-blocs slide-in-left zoom-in-hard"
        id="slide-block-4"
        onClick={handleDepistageClick}
        style={{ backgroundColor: "rgb(168 231 181)" }}
      >
        <img
          src={Loupe}
          className="opacity-delay-effect-1"
          alt="Medecin logo"
        />
        <h2 className="opacity-delay-effect-2">Tester ma santé</h2>
        <p className="opacity-delay-effect-3">
          Quizz de dépistages gratuits et rapides
        </p>
      </div> */}
      <a
        className="action-blocs slide-in-right zoom-in-hard"
        href={AppCultureSante}
        target="_blank"
        rel="noreferrer"
        id="slide-block-2"
        onClick={() => {
          GA4Events.actionHome("Patient");
        }}
      >
        <img
          className="opacity-delay-effect-1"
          src={Doigt}
          alt="Patient logo"
        />
        <h2 className="opacity-delay-effect-2">Je suis Patient</h2>
        <p className="opacity-delay-effect-3">
          Accéder à l'application Culture Santé
        </p>
      </a>
      <a
        className="action-blocs slide-in-bottom zoom-in-hard"
        href={AppCultureSante}
        target="_blank"
        rel="noreferrer"
        id="slide-block-1"
        onClick={() => {
          GA4Events.actionHome("Jeune");
        }}
      >
        <img
          className="opacity-delay-effect-1"
          src={Jeune}
          alt="Patient logo"
        />
        <h2 className="opacity-delay-effect-2">Je suis un jeune</h2>
        <p className="opacity-delay-effect-3">
          Accéder à l'application Culture Santé
        </p>
      </a>
      <div
        className="action-blocs slide-in-right zoom-in-hard"
        id="slide-block-3"
        onClick={handleMedecinClick}
      >
        <img
          src={Patient}
          className="opacity-delay-effect-1"
          alt="Partenaire logo"
        />
        <h2 className="opacity-delay-effect-2">
          Je suis un professionnel de santé
        </h2>
        <p className="opacity-delay-effect-3">Je souhaite découvrir Lisa</p>
      </div>
    </div>
  );
}

export default Actions;
