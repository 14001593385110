import React from "react";

class MapLink extends React.Component {
  getMapLink() {
    const userAgent = window.navigator.userAgent;
    // const address = "13+Rue+Marie+Curie+4+-+10430+-+Rosières-prés-Troyes+-+FRANCE"; // adresse formatée
    const address = "53+Rue+de+la+Paix+-+10000+-+Troyes+-+FRANCE"; // adresse formatée

    if (/iPhone|iPad|iPod/i.test(userAgent)) {
      // Si l'appareil est un iPhone, iPad ou iPod
      return `http://maps.apple.com/?q=${address}`;
    } else if (/Android/i.test(userAgent)) {
      // Si l'appareil est un Android
      return `https://www.google.com/maps/search/?api=1&query=${address}`;
    } else {
      // Pour tous les autres appareils ou navigateurs de bureau
      return `https://www.google.com/maps/search/?api=1&query=${address}`;
    }
  }

  render() {
    const mapLink = this.getMapLink();
    return (
      <a href={mapLink} target="_blank" rel="noopener noreferrer">
        53 Rue de la Paix <br />
        10000 Troyes
        <br />
      </a>
    );
  }
}

export default MapLink;
