import React, { useEffect } from "react";
import Footer from "../components/Footer";
import "../styles/Team_style.scss";
import Diapo from "../components/Diapo";

function Team() {
  return (
    <div id="teamPage">
      <Diapo />
      <div id="team-sect" className="opacity-delay-effect-1">
        <h1>Qui sommes nous ?</h1>
        <div>
          <br />
          <p>
            <b>Lisa Connect</b> est portée par l'histoire personnelle de la
            fondatrice{" "}
            <a
              href="https://www.linkedin.com/in/laetitia-serrano-1475a1150/?originalSubdomain=fr"
              target="_blank"
              rel="noopener noreferrer"
              alt="Laetitia Serrano linkedin account"
            >
              Laetitia Serrano
            </a>
            .
          </p>
          <br />
          <p>
            Suite à de <b>longues heures passées en salle d'attente</b>, elle
            constate l'anxiété et le stress vécu dans ce lieu incontournable. Un
            constat partagé avec les professionnels de santé: l'attente pourrait
            être <b>enrichie de conseils, d'informations et de préventions</b>.
          </p>
          <p>
            Lisa Connect revisite le parcours de soin et{" "}
            <b>invite au dépistage</b> de manière ludique et interactive.
          </p>
          <br />
          <p>
            Une réponse à un enjeu populationnel en adressant la{" "}
            <b>
              prévention auprès du plus grand nombre et sans distinctions
              sociales
            </b>
            .
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Team;
