import React, { useEffect } from "react";
import "../styles/Diapo_style.scss";
import LisaLogo from "../ressources/LisaConnect-couleur.png";
import LCxEmeuse from "../ressources/quizz/LC-Emeuse.png";
import LisaLogoLight from "../ressources/LisaConnect-couleur_350px.png";
import { Link, useNavigate } from "react-router-dom";

function DiapoEMeuse() {
  const navigate = useNavigate();

  return (
    <div
      id="diapo-sect"
      className="lcxmeuseBloc"
      style={{ height: "auto", padding: "3vw" }}
    >
      {/* <Link to="/" className="zoom-in-light"> */}
      <img
        src={LCxEmeuse}
        id="lisa-birds"
        className="opacity-effect zoom-in-light lcxmeuseImg"
        alt="LisaConnect logo"
        onClick={() => navigate("/")}
        style={{ maxWidth: "400px", width: "35vw", marginTop: "3vh" }}
      />
      {/* </div> */}
      {/* </Link> */}
    </div>
  );
}

export default DiapoEMeuse;
